import * as React from "react";
import { useState } from "react";
import { observer } from 'mobx-react';
import Layout from "../../components/layout";
import useStores from "../../hooks/use-stores";
// @ts-ignore
import redPlus from "../../images/redPlus.png";

// state name and state code array
const australianStates: string[] = [
  "Australian Capital Territory", "New South Wales", "Victoria", "Queensland",
  "South Australia", "Western Australia", "Tasmania", "Northern Territory"
];
const australianStateCodes: string[] = ["ACT", "NSW", "VIC", "QLD", "SA", "WA", "TAS", "NT"];

const PropertyEditPage = observer(() => {
  // two modes for the whole page: edit mode or create mode
  const [editPage, setEditPage] = useState<boolean>(true);
  React.useEffect(() => { console.log("edit mode: ", editPage) }, [editPage]);
  // create form variables -- Property
  const [propertyId, setPropertyId] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [saleStatus, setSaleStatus] = useState<number>(0);
  const [propertyNature, setPropertyNature] = useState<number>(0);
  const [propertyType, setpropertyType] = useState<number>(0);
  const [bedrooms, setBedrooms] = useState<number>(0);
  const [bathrooms, setBathrooms] = useState<number>(0);
  const [carSpace, setCarSpace] = useState<number>(0);
  const [floorSize, setFloorSize] = useState<number>(0);
  const [landSize, setLandSize] = useState<number>(0);
  const [priceInfo, setPriceInfo] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  // create form variables -- Address
  const [unitNumber, setUnitNumber] = useState<number>(0);
  const [streetNumber, setStreetNumber] = useState<string>("");
  const [streetName, setStreetName] = useState<string>("");
  const [suburbName, setSuburbName] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [stateCode, setStateCode] = useState<string>("");
  // const index to control two variables at the same time
  const [stateIndex, setStateIndex] = useState<string>("0");
  React.useEffect(() => {
    setStateName(australianStates[parseInt(stateIndex)]);
    setStateCode(australianStateCodes[parseInt(stateIndex)]);
  }, [stateIndex])
  // fix statusDetail
  const statusDetailList: string[] = ["Sale", "Rent"];
  const [statusDetail, setStatusDetail] = useState<string>("");
  React.useEffect(() => {
    setStatusDetail(statusDetailList[saleStatus - 1])
  }, [saleStatus])
  // fix timelisted and updateat
  const [timeListed, setTimeListed] = useState<string>("");
  const [updateAt, setUpdateAt] = useState<string>("");
  useState(() => {
    setTimeListed(Date.now().toString())
    setUpdateAt(Date.now().toString())
  })
  // ====== submit table ======
  // create store
  const { propertyStore } = useStores();
  const { addressStore } = useStores();
  // create address in back-end
  const handleCreate = () => {
    addressStore.createAddress({
      unitNumber,
      streetNumber,
      streetName,
      suburb: {
        name: suburbName,
        postcode,
        state: stateName,
        stateCode,
      },
    })
    //   // set propertyId
    //   console.log(addressStore.addressInfo.propertyId);
    //   propertyId = addressStore.addressInfo.propertyId;
  }
  React.useEffect(() => {
    console.log("propertyId obtained:", addressStore.addressInfo.propertyId);
    if (addressStore.addressInfo.propertyId !== 0) {
      setPropertyId(addressStore.addressInfo.propertyId);
    }
  }, [addressStore.addressInfo.propertyId])
  React.useEffect(() => {
    // create property in back-end using propertyId
    if (addressStore.addressInfo.propertyId !== 0) {
      propertyStore.createProperty({
        title,
        propertyId,
        saleStatus,
        statusDetail,
        propertyNature,
        propertyType,
        bedrooms,
        bathrooms,
        carSpace,
        floorSize,
        landSize,
        priceInfo,
        description,
        price: 0,
        timeListed: "2022-11-11",
        updateAt: "2022-11-11"
      })
      console.log(
        title,
        propertyId,
        saleStatus,
        statusDetail,
        propertyNature,
        propertyType,
        bedrooms,
        bathrooms,
        carSpace,
        floorSize,
        landSize,
        priceInfo,
        description,
        0)
    }
  }, [propertyId])


  // collapse and expand for sections, todo
  // show more input box
  const [inspectionNum, setInspectionNum] = useState(1);
  const [bedroomInput, setBedroomInput] = useState(false);
  React.useEffect(() => {
    bedrooms >= 4 ? setBedroomInput(true) : setBedroomInput(false)
  }, [bedrooms])
  const [bathroomInput, setBathroomInput] = useState(false);
  React.useEffect(() => {
    bathrooms >= 4 ? setBathroomInput(true) : setBathroomInput(false)
  }, [bathrooms])
  const [carSpaceInput, setCarSpaceInput] = useState(false);
  React.useEffect(() => {
    carSpace >= 4 ? setCarSpaceInput(true) : setCarSpaceInput(false)
  }, [carSpace])
  // ============ return html ============
  return (
    <Layout>
      <h6>
        Current Location: Housing Stock - Sell - <span className="font-semibold">New Property</span>
      </h6>
      {/* ====== property info ====== */}
      <section>
        <div>
          <h1>PROPERTY INFORMATION</h1>
          {/* <button onClick={() => { setEditPage(true) }}>123</button> */}
          {/* <input type="text" disabled={editPage} /> */}
        </div>
        <div className="grid grid-cols-[9rem_54rem] gap-3">
          <label>Title</label>
          <input type="text" placeholder="eg: Fabulous Apartment in Convenient Central Location"
            value={title} onChange={(event) => setTitle(event.target.value)} />
          {/* todo change width based on 255varchar */}
          {editPage &&
            <div className="col-span-2">
              <label className="inline-block w-[156px]">PropertyId:&nbsp;</label>
              <label>{propertyId}</label>
            </div>
          }
          <label>Type of delegation</label>
          <div className="py-1.5 [&>label]:ml-1 [&>label]:mr-3">
            <input type="radio" name="delegation" onChange={() => setSaleStatus(1)} />
            <label>Sell</label>
            <input type="radio" name="delegation" onChange={() => setSaleStatus(2)} />
            <label>Rent</label>
          </div>
          <label>Property nature</label>
          <div className="py-1.5 [&>label]:ml-1 [&>label]:mr-3">
            <input type="radio" name="nature" onChange={() => setPropertyNature(1)} />
            <label>Residential</label>
            <input type="radio" name="nature" onChange={() => setPropertyNature(2)} />
            <label>Commercial</label>
          </div>
          <label>Property type</label>
          <div className="py-1.5 [&>label]:ml-1 [&>label]:mr-3">
            <input type="radio" name="type" onChange={() => setpropertyType(1)} />
            <label>House</label>
            <input type="radio" name="type" onChange={() => setpropertyType(2)} />
            <label>Apartment</label>
          </div>
        </div>
      </section>
      {/* ====== property address ====== */}
      <section>
        <div>
          <h1>ADDRESS</h1>
        </div>
        <div className="flex items-start [&>*]:w-1/2">
          <aside className="grid grid-cols-[9rem_15rem] gap-3">
            <label>Unit number</label>
            <input type="number" placeholder="(optional)"
              value={unitNumber} onChange={(event) => setUnitNumber(parseInt(event.target.value))} />
            {/* tofix set default to 0 but not show */}
            <label>Street number</label>
            <input type="text"
              value={streetNumber} onChange={(event) => setStreetNumber(event.target.value)} />
            <label>Street name</label>
            <input type="text"
              value={streetName} onChange={(event) => setStreetName(event.target.value)} />
          </aside>
          <aside className="grid grid-cols-[9rem_15rem] gap-3">
            <label>Suburb name</label>
            <input type="text"
              value={suburbName} onChange={(event) => setSuburbName(event.target.value)} />
            <label>Postcode</label>
            <input type="text"
              value={postcode} onChange={(event) => setPostcode(event.target.value)} />
            <label>State</label>
            <select className="w-48" defaultValue={99} onChange={(event) => setStateIndex(event.target.value)}>
              <option value={99} disabled hidden>	&#40; choose state	&#41;</option>
              {australianStates.map((australianState, index) =>
                <option key={index} value={index}>{australianState}</option>
              )}
            </select>
          </aside>
        </div>
      </section>
      {/* ====== house info ====== */}
      <section>
        <div>
          <h1>PROPERTY INFORMATION</h1>
        </div>
        <div className="flex items-start [&>*]:w-1/2">
          <aside className="grid grid-cols-[9rem_15rem] gap-3">
            <label>Price information</label>
            <input type="text" value={priceInfo} onChange={(event) => setPriceInfo(event.target.value)} />
            <label>Floor size</label>
            <input type="text" value={floorSize} onChange={(event) => setFloorSize(parseInt(event.target.value))} />
            <label>Land size</label>
            <input type="text" value={landSize} onChange={(event) => setLandSize(parseInt(event.target.value))} />
          </aside>
          <aside className="grid grid-cols-[9rem_15rem] gap-3 ">
            <label >Bedroom</label>
            <div>
              <select className="w-32" defaultValue={0}
                onChange={(event) => setBedrooms(parseInt(event.target.value))}>
                <option value={0} disabled hidden></option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>(specify)</option>
              </select>
              {bedroomInput && <input type="number" className="w-24 ml-4"
                value={bedrooms} onChange={(event) => setBedrooms(parseInt(event.target.value))} />}
            </div>
            <label >Bathroom</label>
            <div>
              <select className="w-32" defaultValue={0}
                onChange={(event) => setBathrooms(parseInt(event.target.value))}>
                <option value={0} disabled hidden></option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>(specify)</option>
              </select>
              {bathroomInput && <input type="number" className="w-24 ml-4"
                value={bathrooms} onChange={(event) => setBathrooms(parseInt(event.target.value))} />}
            </div>
            <label >Car space</label>
            <div>
              <select className="w-32" defaultValue={0}
                onChange={(event) => setCarSpace(parseInt(event.target.value))}>
                <option value={0} disabled hidden></option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>(specify)</option>
              </select>
              {carSpaceInput && <input type="number" className="w-24 ml-4"
                value={carSpace} onChange={(event) => setCarSpace(parseInt(event.target.value))} />}
            </div>
          </aside>
        </div>
      </section>
      {/* ====== property description ====== */}
      <section>
        <div>
          <h1>PROPERTY DESCRIPTION</h1>
        </div>
        <textarea className="w-full" rows={4} placeholder="Describe the property in detail .."
          value={description} onChange={(event) => setDescription(event.target.value)} />
        {/* adjust the number of rows based on max length in db, 2048 varchar todo */}
      </section>
      {/* ====== owner info (not available in backend)====== */}
      <section>
        <div>
          <div>
            <h1>PROPERTY OWNER</h1>
          </div>
        </div>
        {/*<div className="grid grid-cols-[9rem_21.5rem] gap-3">
          <label>Name</label>
          <input type="text" />
          <label>Email</label>
          <input type="email" />
          <label>Phone</label>
          <input type="text" />
        </div>*/}
      </section>
      {/* ====== inspection and auction info ====== */}
      <section>
        <div>
          <h1>INSPECTION / AUCTION</h1>
        </div>
        <div className="grid grid-cols-[9rem_33rem] gap-3">
          <label>Inspection time</label>
          {/* inspection 1 */}
          <div className="col-start-2">
            <input className="mr-8" type="date" />
            <input type="time" />
            &nbsp;-&nbsp;
            <input type="time" />
            <img className="w-6 ml-8 inline-block cursor-pointer" src={redPlus} onClick={() => setInspectionNum(inspectionNum + 1)} />
          </div>
          {/* inspection 2 */}
          {(inspectionNum >= 2) &&
            <div className="col-start-2">
              <input className="mr-8" type="date" />
              <input type="time" />
              &nbsp;-&nbsp;
              <input type="time" />
              <img className="w-6 ml-8 inline-block cursor-pointer" src={redPlus} onClick={() => setInspectionNum(inspectionNum + 1)} />
            </div>
          }
          {/* inspection 3 */}
          {(inspectionNum >= 3) &&
            <div className="col-start-2">
              <input className="mr-8" type="date" />
              <input type="time" />
              &nbsp;-&nbsp;
              <input type="time" />
            </div>
          }
          {/* ====== auction part ====== */}
          <label>Auction time</label>
          <div>
            <input className="mr-8" type="date" />
            <input type="time" />
            &nbsp;-&nbsp;
            <input type="time" />
          </div>
          <label>Auction location</label>
          <input type="text" />
        </div>
      </section>
      {/* ====== rental information ====== */}
      <section>
        <div>
          <h1>RENTAL INFORMATION</h1>
        </div>
        <div className="grid grid-cols-[9rem_10.5rem_9rem_10.5rem_9rem_10.5rem] gap-3">
          <label>Date available</label>
          <input type="text" />
          <label>Lease term</label>
          <input type="text" />
          <label>Application</label>
          <input type="text" />
        </div>
        <div className="my-3 py-1.5 [&>*>label]:ml-1 [&>*>label]:mr-3 grid grid-cols-9 gap-3">
          <span>
            <input type="checkbox" name="gas" /><label>Gas</label>
          </span><span>
            <input type="checkbox" name="aircon" /><label>Air conditioning</label>
          </span><span>
            <input type="checkbox" name="builtInWardrobes" /><label>Built in wardrobes</label>
          </span><span>
            <input type="checkbox" name="pool" /><label>Swimming pool</label>
          </span>
        </div>
        <textarea className="w-full" name="otherInfo" rows={4} />
      </section>
      <div className="p-3 flex justify-end">
        <button className="red-button"
          onClick={handleCreate}
        ><div className="mx-6 inline-block">Create</div></button>
        <button className="white-button"><div className="mx-6 inline-block">Cancel</div></button>
      </div>
    </Layout>
  );
});

export default PropertyEditPage;

export const Head = () => <title>Real estate | Sell | Edit</title>;